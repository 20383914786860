import React, { useState, useEffect } from "react";

let dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

function GoogleAnalytics() {
  useEffect(() => {
    setTimeout(() => {
      gtag("js", new Date());
      gtag("config", "G-2LRBF23XRS", { anonymize_ip: true });
    }, 15000);
  }, []);

  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-2LRBF23XRS"
      ></script>
    </>
  );
}

export default GoogleAnalytics;
