import React from "react";
import ReactDOM from "react-dom/client";
import Detector from "./Detector";
import Upload from "./Upload";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Detector />,
  },
  {
    path: "/non-public/upload/",
    element: <Upload />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
