import React, { useState } from "react";
import axios from "axios";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

function FeedbackForm(props) {
  const { text, disabled, setDisabled } = props;
  const gaEventTracker = useAnalyticsEventTracker("Feedback Form");

  const handleThumbsUp = () => {
    gaEventTracker("Positive Feedback");
    setDisabled(true);
    axios.post("https://demo.thecheckerai.com/api/feedback", {
      text: text,
      feedback: "positive",
    });
  };

  const handleThumbsDown = () => {
    gaEventTracker("Negative Feedback");
    setDisabled(true);
    axios.post("https://demo.thecheckerai.com/api/feedback", {
      text: text,
      feedback: "negative",
    });
  };

  return (
    <div>
      <p className="mt-6 font-bold">Was this correct?</p>
      <p className="font-light mb-4">
        We are using the open beta phase as a way to get feedback on the
        performance of our model. <br></br>If the origin is known, consider
        giving us feedback so we can update the model when needed.
      </p>
      <p className="font-light italic mb-4">
        By providing feedback you agree with us saving the input text, your IP
        address and feedback.
      </p>

      <div className="flex justify-center  gap-2">
        <button
          onClick={handleThumbsUp}
          className="bg-green-500 hover:bg-green-700 text-white font-old py-2 px-4 rounded disabled:opacity-75"
          disabled={disabled}
        >
          <FaThumbsUp className="h-5 w-5" />
        </button>
        <button
          onClick={handleThumbsDown}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded disabled:opacity-75"
          disabled={disabled}
        >
          <FaThumbsDown className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
}

export default FeedbackForm;
