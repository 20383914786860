import React from "react";

const FeaturedOn = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
        <p className="text-center text-3xl font-semibold text-gray-700">
          As Featured On{" "}
        </p>
        <div className="mt-6 grid grid-cols-3 gap-0.5 md:grid-cols-4 lg:mt-8">
          <div className="col-span-1 flex justify-center">
            <a href="https://www.afr.com/technology/cheaters-beware-this-program-can-tell-if-chatgpt-did-your-homework-20230110-p5cbl1">
              <img
                className="h-32"
                src={require("./static/images/AFR_logo.png")}
                alt="AFR logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://youtu.be/rMtX48uGh94">
              <img
                className="h-32"
                src={require("./static/images/UTS_startups.png")}
                alt="UTS Startups logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.forbes.com/sites/dereknewton/2023/01/24/the-big-profitable-education-race-to-detect-chatgpt/">
              <img
                className="h-32"
                src={require("./static/images/Forbes_logo.png")}
                alt="Forbes logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://youtu.be/MF5f11zHS1E">
              <img
                className="h-36"
                src={require("./static/images/7News.png")}
                alt="7News logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.abc.net.au/news/2023-01-24/what-is-chatgpt-how-can-it-be-detected-by-school-university/101884388">
              <img
                className="h-32"
                src={require("./static/images/ABCNews.jpg")}
                alt="ABC News logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.theeducatoronline.com/k12/news/how-schools-can-stop-students-cheating-with-chatgpt/281783">
              <img
                className="h-28"
                src={require("./static/images/Educator.png")}
                alt="The Educator Online logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.timeshighereducation.com/depth/inside-post-chatgpt-scramble-create-ai-essay-detectors">
              <img
                className="h-32"
                src={require("./static/images/TimesHigherEducation.png")}
                alt="Times Higher Education logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.couriermail.com.au/queensland-education/artificial-intelligence-software-chatgpts-mock-exam-answers-pass-plagiarism-checks-but-ai-detection-software-available/news-story/7e612a21840fbb19eb6f8f3422d9d087">
              <img
                className="h-32"
                src={require("./static/images/Courier-Mail.jpg")}
                alt="The Courier Mail logo"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.thestar.com/news/gta/2023/01/21/chatgpt-is-a-pretty-good-student-why-schools-need-to-figure-out-this-ai-technology-fast.html">
              <img
                className="h-32"
                src={require("./static/images/Toronto_Star-Logo.png")}
                alt="Toronto Star"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.linkedin.com/posts/mdavidcyrus_chatgpt-ai-artificialintelligence-activity-7023393929114914816-y1Uk/?utm_source=share&utm_medium=member_desktop">
              <img
                className="h-32"
                src={require("./static/images/ABCRadio.jpg")}
                alt="ABC Radio logo"
              />
            </a>
          </div>

          <div className="col-span-1 flex justify-center w-20%">
            <a href="https://www.open.edu.au/advice/insights/ethical-way-to-use-chatgpt-as-a-student">
              <img
                className="h-24"
                src={require("./static/images/OpenUniversitiesAustralia.png")}
                alt="Open Universities Australia"
              />
            </a>
          </div>
          <div className="col-span-1 flex justify-center">
            <a href="https://www.rnz.co.nz/news/world/483039/chatgpt-what-is-it-and-why-is-it-being-banned-in-some-places">
              <img
                className="h-24"
                src={require("./static/images/RNZ_logo.png")}
                alt="RNZ logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedOn;
