import React from "react";

import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const ShareWidget = () => {
  const url = "https://demo.thecheckerai.com";
  const handleFacebookShare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
  };

  const handleTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=Check out The Checker AI&url=${url}`
    );
  };

  const handleLinkedInShare = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
  };

  const handleEmailShare = () => {
    window.open(`mailto:?subject=Check out The Checker AI&body=${url}`);
  };

  return (
    <div className="flex flex-col items-center text-center mt-5">
      <h2 className="text-lg font-medium">Share with Others</h2>
      <div className="flex">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full m-4"
          onClick={handleFacebookShare}
        >
          <FaFacebook className="text-2xl" />
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full m-4"
          onClick={handleTwitterShare}
        >
          <FaTwitter className="text-2xl" />
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full m-4"
          onClick={handleLinkedInShare}
        >
          <FaLinkedin className="text-2xl" />
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full m-4"
          onClick={handleEmailShare}
        >
          <MdEmail className="text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default ShareWidget;
