import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import ReactGA from "react-ga";
import { VideoCameraIcon } from "@heroicons/react/20/solid";

import FeedbackForm from "./FeedbackForm";
import Email from "./Email";
import Featured from "./Featured";
import CheckerAI from "./static/images/checkerai.png";
import GoogleAnalytics from "./GoogleAnalytics";
import ShareWidget from "./ShareWidget";
import CookieConsent, { Cookies } from "react-cookie-consent";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import Swal from "sweetalert2";

const Detector = () => {
  const gaEventTracker = useAnalyticsEventTracker("Detector");

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [humanScore, setHumanScore] = useState(null);
  const [aiScore, setAIScore] = useState(null);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const TRACKING_ID = "UA-254243463-1"; // OUR_TRACKING_ID

  const CHATGPT_TEXT = `Plagiarism is a significant issue in modern education, as the internet has made it easier for students to copy and paste content without attribution. Educational institutions have responded by implementing strict rules and policies around academic integrity, and using plagiarism detection software to identify instances of plagiarism. The history of plagiarism in education dates back to the 19th century, when universities began to develop regulations around academic misconduct. However, it was not until the internet era that plagiarism became a widespread problem in education.

  Present-day institutions offer resources and education to help students avoid plagiarism, as well as penalties for those found to have plagiarized. However, there are ongoing debates about how best to address plagiarism, with some arguing that a focus on punishment may not be effective in promoting academic integrity. The history of plagiarism in education highlights the ongoing need for vigilance and action to maintain academic integrity in the face of new technological challenges.`;

  const GPT_TEXT = `Plagiarism in education has been an issue since ancient times. In the classical world, plagiarism was seen as a form of literary theft with authors claiming the work of others as their own. In the Middle Ages, the concept of intellectual property was not as well understood and plagiarism was seen as a form of cheating. The Renaissance saw an increase in the use of printed materials and a need for authors to protect their work from plagiarism. In the eighteenth century, plagiarism was increasingly being seen as a form of intellectual dishonesty and was punishable by fines and imprisonment in some cases. By the nineteenth century, plagiarism had become a major issue in the academic world, with universities introducing formal policies and procedures to address the problem. Today, plagiarism remains a major concern in education, with institutions using sophisticated software systems to detect and prevent it.

  The consequences of plagiarism in education can be serious, ranging from suspension and expulsion to damage to one's reputation and career. Institutions have implemented a variety of measures to detect and prevent plagiarism, including monitoring student work, reducing the use of internet sources, and educating students about the ethical implications of plagiarism. Despite these efforts, plagiarism remains a problem in education and will likely continue to be an issue in the future.`;
  const HUMAN_TEXT = `Previous research into the reasons for plagiarism within higher education demonstrated that the main reasons for committing plagiarism include ease of copying and ease of access to materials and new technologies. In relation to new technologies, a student survey conducted by our team at Edukado AI in early December 2022, revealed that university students were likely to use AI writing tools as a result of having to meet tight assessment deadlines. 

  They also expressed that they found assessments very time consuming and also highlighted writing skill difficulties. Majority of students from this cohort acknowledged that they interpreted the use of AI writing tools as cheating and would be deterred from using them if a detection method became available. Other research into the impact of ChatGPT on education was conducted, which touched on the idea of reverting back to physical closed-book exams where the students write by hand.`;

  useEffect(() => {
    setTimeout(() => {
      ReactGA.initialize(TRACKING_ID);
    }, 15000);
    checkUsageAndShowPopup();
  }, []);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const addPresetText = (value) => {
    setText(value);
  };

  const checkUsageAndShowPopup = () => {
    const usageCount = parseInt(localStorage.getItem("usageCount")) || 0;
    if (usageCount >= 3) {
      // Call Sweetalert popup function
      showSignUpPopup();
    }
  };

  const showSignUpPopup = () => {
    Swal.fire({
      title: "Sign up",
      text: "Get access to our new platform now.",
      icon: "info",
      confirmButtonText: "Sign Up",
      preConfirm: () => {
        // Redirect to signup page
        window.location.href = "https://thecheckerai.com/signup";
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (text.length < 100) {
      setError("Text must be at least 100 characters long.");
      setLoading(false);
      return;
    }

    setError("");

    axios
      .post("https://demo.thecheckerai.com/api/detect", { text })
      .then((response) => {
        console.log(response);
        setAIScore(response.data.probability_fake);
        setHumanScore(response.data.probability_real);
        setGradeLevel(response.data.grade_level);
        setDisabled(false);
        setLoading(false);
        let usageCount = parseInt(localStorage.getItem("usageCount")) || 0;
        usageCount++;
        localStorage.setItem("usageCount", usageCount);
        checkUsageAndShowPopup();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <GoogleAnalytics />
      <div className="container mx-auto py-10 w-2/3">
        <img src={CheckerAI} className="mx-auto block mb-8 w-2/5" alt="Logo" />

        <div className="">
          <p className="text-gray-700 text-lg leading-relaxed mb-8 text-center">
            Introducing The Checker AI, formerly AICheatCheck, now a vital part
            of the EduLink AI family. We're raising the bar in education with AI
            innovation, and The Checker AI is a testament to this promise.
            Designed to uphold academic integrity, this tool verifies student
            work authenticity with an accuracy rate of 99.7%. <br /> <br />
            Discover more in our{" "}
            <a
              href="https://cdn.aicheatcheck.com/Keeping_AI_Honest_in_Education__Identifying_GPT_generated_text.pdf"
              className="text-blue-500 underline hover:text-blue-800"
              target="_blank"
            >
              whitepaper
            </a>{" "}
            and see how we're transforming the educational landscape at{" "}
            <a
              href="https://edulinkai.com"
              className="text-blue-500 underline hover:text-blue-800"
              target="_blank"
            >
              edulinkai.com
            </a>
            . <br /> Ready to experience The Checker AI in your institution?{" "}
            <br /> <br />
            Get in touch at{" "}
            <a
              href="mailto:contact@edulinkai.com"
              className="text-blue-500 underline hover:text-blue-800"
              target="_blank"
            >
              contact@edulinkai.com
            </a>{" "}
            or schedule a meeting below.
          </p>
        </div>
        <div className="flex justify-center mb-8 gap-4">
          <a href="https://calendly.com/edukadoai/demo" target="_blank">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <VideoCameraIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Schedule a Meeting
            </button>
          </a>
          <a href="https://edulinkai.com" target="_blank">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Visit Our Website
            </button>
          </a>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <p className="text-2xl font-bold mb-5">
            Enter your text below or use one of our examples:
          </p>

          <div className="flex space-x-2 mb-4">
            <button
              type="button"
              className="py-2 px-4 rounded-md bg-blue-500 text-white"
              onClick={() => addPresetText(CHATGPT_TEXT)}
            >
              ChatGPT
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-md bg-blue-500 text-white"
              onClick={() => addPresetText(GPT_TEXT)}
            >
              GPT-3
            </button>
            <button
              type="button"
              className="py-2 px-4 rounded-md bg-blue-500 text-white"
              onClick={() => addPresetText(HUMAN_TEXT)}
            >
              Human
            </button>
          </div>
          <div className="relative rounded-md shadow-sm w-3/4">
            <textarea
              className="form-input py-3 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:leading-5 h-96 bg-gray-100 text-lg"
              placeholder="Enter text here..."
              value={text}
              onChange={handleChange}
            />
            <p className="mt-4 text-grey-500 text-center font-light">
              Please note that our system currently only works with the English
              language and is most accurate above 50 words.
            </p>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-6 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-800 mr-2"
              onClick={() => gaEventTracker("check")}
            >
              {loading ? (
                <FaSpinner className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              ) : (
                "Check"
              )}
            </button>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-6 text-sm leading-5 font-medium rounded-md text-black border border-blue-500 hover:border-blue-600 focus:outline-none focus:border-blue-800 mt-1"
              onClick={() => setText("")}
            >
              Clear
            </button>
          </div>
          {error && (
            <div className="mt-6 text-center text-red-700">{error}</div>
          )}
        </form>

        {aiScore && (
          <div className="mt-6 text-center">
            <p className="text-4xl font-bold">
              {humanScore > aiScore
                ? `${(humanScore * 100).toFixed(
                    0
                  )}% certain the text was generated by a Human`
                : `${(aiScore * 100).toFixed(
                    0
                  )}% certain the text was written by an AI`}
            </p>
            <p className="text-3xl font-light mt-6">
              Grade Level: {gradeLevel}
            </p>
            <FeedbackForm
              text={text}
              disabled={disabled}
              setDisabled={setDisabled}
            />
          </div>
        )}
        <ShareWidget />
        <Featured />
        <Email />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I understand!"
        cookieName="consent"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "black",
          borderRadius: "0.3rem",
          paddingLeft: "0.8rem",
          paddingRight: "0.8rem",
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          fontSize: "13px",
          backgroundColor: "#0cff0c",
        }}
        expires={150}
      >
        The Checker AI uses cookies to gain better insights in improving our
        product, and offering a better user experience. By continuing to browse
        the site you're agreeing to our use of cookies.{" "}
      </CookieConsent>

      <footer className="bg-gray-900 text-white py-4">
        <div className="container mx-auto flex items-center justify-center flex-wrap">
          <div className="w-full text-center">
            <h5 className="uppercase tracking-widest text-sm font-bold mb-2">
              Contact
            </h5>
            <ul className="list-reset mb-6">
              <li className="mb-2">
                <a
                  href="mailto:contact@edulinkai.com"
                  className="text-white hover:text-gray-300"
                >
                  contact@edulinkai.com
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="https://www.linkedin.com/company/aicheatcheckbyedukadoai/"
                  target="_blank"
                  className="text-white hover:text-gray-300"
                >
                  LinkedIn
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="./static/docs/PrivacyPolicy.pdf"
                  target="_blank"
                  className="text-white hover:text-gray-300"
                >
                  Privacy Policy
                </a>

                {"        "}
                <br></br>
                <a
                  href="./static/docs/CookiePolicy.pdf"
                  target="_blank"
                  className="text-white hover:text-gray-300"
                >
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Detector;
