import React, { useState } from "react";
import axios from "axios";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

function EmailOptIn() {
  const gaEventTracker = useAnalyticsEventTracker("Email Form");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  function handleSubmit(event) {
    event.preventDefault();

    if (email.length < 5 || occupation.length < 3) {
      setError("Please fill in your e-mail and occupation.");
      return;
    }
    setError("");

    axios
      .post("https://demo.thecheckerai.com/api/details", {
        email,
        name,
        occupation,
      })
      .then((response) => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.error(error);
      });
    // send the email and occupation to the server
  }

  return (
    <div className=" bg-gray-200 p-8 mt-8 rounded-3xl">
      {error && <div className="mt-6 text-center text-red-700">{error}</div>}
      <p className="font-bold ">Want to be kept up-to-date on our progress?</p>
      <p className="mb-5 mt-2">
        We are working on an educational tool which helps teachers, professors
        and other academic staff to recognize AI written content.
      </p>
      {!submitted ? (
        <>
          <form onSubmit={handleSubmit} className="items-center">
            <div className="flex gap-5">
              <label className="flex-grow text-gray-700" htmlFor="email">
                <span className="block font-bold mb-2 text-xs uppercase">
                  Name
                </span>
                <input
                  className="form-input w-full py-2 px-2 rounded-md"
                  type="name"
                  id="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </label>
              <label className="flex-grow text-gray-700" htmlFor="email">
                <span className="block font-bold mb-2 text-xs uppercase">
                  Email
                </span>
                <input
                  className="form-input w-full py-2 px-3 rounded-md"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </label>

              <label className="flex-grow text-gray-700" htmlFor="occupation">
                <span className="block font-bold mb-2 text-xs uppercase">
                  Occupation
                </span>
                <div className="relative rounded-md shadow-sm">
                  <select
                    className="form-select block w-full py-2 px-3 leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    id="occupation"
                    value={occupation}
                    onChange={(event) => setOccupation(event.target.value)}
                  >
                    <option value="">Select your occupation</option>
                    <option value="student">Student</option>
                    <option value="teacher">Teacher/Professor</option>
                    <option value="school_faculty">School Faculty</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </label>
            </div>

            <button
              type="submit"
              className="bg-green-500 hover:btn-green-700 text-white font-old py-2 px-8 rounded-full mt-4"
              onClick={() => {
                gaEventTracker("Email Opt-in");
              }}
            >
              Subscribe
            </button>
          </form>
        </>
      ) : (
        <div className="text-center mt-8">
          <p className="text-2xl font-bold mb-2">Thank you!</p>
          <p>We will keep you updated on our progress.</p>
        </div>
      )}
    </div>
  );
}

export default EmailOptIn;
