import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import TagManager from "react-gtm-module";

import FeedbackForm from "./FeedbackForm";
import Email from "./Email";
import Featured from "./Featured";
import logo from "./logo.png";
import GoogleAnalytics from "./GoogleAnalytics";
import ShareWidget from "./ShareWidget";
import CookieConsent, { Cookies } from "react-cookie-consent";

function AIWriterSelection({ setSelectedOption }) {
  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="relative flex flex-col items-center justify-center py-4">
        <label className="block text-lg font-medium leading-5 text-gray-800 cursor-pointer py-4 px-8 bg-gray-300 rounded-md border-2 border-gray-700">
          <input
            type="radio"
            className="form-radio"
            name="written_by"
            value="AI"
            onChange={handleChange}
          />
          <span className="ml-3">AI</span>
        </label>
        <label className="block text-lg font-medium leading-5 text-gray-800 cursor-pointer py-4 px-8 bg-gray-300 rounded-md border-2 border-gray-700">
          <input
            type="radio"
            className="form-radio"
            name="written_by"
            value="Human"
            onChange={handleChange}
          />
          <span className="ml-3">Human</span>
        </label>
      </div>
    </div>
  );
}

const Upload = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [classification, setSelectedOption] = useState("AI");

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (text.length < 100) {
      setError("Text must be at least 100 characters long.");
      setLoading(false);
      return;
    }

    setError("");

    axios
      .post("https://demo.thecheckerai.com/api/data", { text, classification })
      .then((response) => {
        setDisabled(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <GoogleAnalytics />
      <div className="container mx-auto py-10 w-2/3">
        <img src={logo} className="mx-auto block mb-8" alt="Logo" />

        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <div className="relative rounded-md shadow-sm w-2/3">
            <textarea
              className="form-input py-3 px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:leading-5 h-64 bg-gray-100 text-md"
              placeholder="Enter text here..."
              value={text}
              onChange={handleChange}
            />
            <p className="mt-4 text-grey-500 text-center font-light">
              Make sure to classify if an AI generated this or not.
            </p>
            <AIWriterSelection
              classification={classification}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <div className="mt-6">
            <button
              onClick={() => {
                setText("");
              }}
              className="group relative w-full flex justify-center py-3 px-6 border border-transparent text-sm leading-5 font-medium rounded-md text-black"
            >
              Clear
            </button>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-6 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-800"
            >
              {loading ? (
                <FaSpinner className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
          {error && (
            <div className="mt-6 text-center text-red-700">{error}</div>
          )}
        </form>
      </div>
    </>
  );
};

export default Upload;
